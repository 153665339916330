import { m, useIsPresent } from "framer-motion";
import type { LayoutSideMenuGroup } from "../../../types";
import { useInitialValue } from "../../../hooks/use-initital-value";
import { useSectionStore } from "../../util/layout-grid/section-context";
import { remToPx } from "../../../util/layout";
import type { SideMenuReturnVariantProps } from "./side-menu.variants";

interface SideMenuVisibleSectionHighlightProps {
  styles: SideMenuReturnVariantProps;
  group: LayoutSideMenuGroup;
  pathname: string;
}

function SideMenuVisibleSectionHighlight({
  styles,
  group,
  pathname,
}: SideMenuVisibleSectionHighlightProps) {
  const [sections, visibleSections] = useInitialValue(
    [
      useSectionStore((s) => s.sections),
      useSectionStore((s) => s.visibleSections),
    ],
    false
  );

  const isPresent = useIsPresent();
  const firstVisibleSectionIndex = Math.max(
    0,
    [{ id: "_top" }, ...sections].findIndex(
      (section) => section.id === visibleSections[0]
    )
  );
  const itemHeight = remToPx(2);

  const height = isPresent
    ? Math.max(1, visibleSections.length) * itemHeight
    : itemHeight;

  const top =
    group.menu.findIndex((item) => item.link === pathname) * itemHeight +
    firstVisibleSectionIndex * itemHeight;

  return (
    <m.div
      animate={{ opacity: 1, transition: { delay: 0.1 } }}
      className={styles.highlight()}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      layout
      style={{ borderRadius: 6, height, top }}
    />
  );
}

export default SideMenuVisibleSectionHighlight;
