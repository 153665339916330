import { m } from "framer-motion";
import type { LayoutSideMenuGroup } from "../../../types";
import { remToPx } from "../../../util/layout";
import type { SideMenuReturnVariantProps } from "./side-menu.variants";

interface SideMenuActivePageMarkerProps {
  styles: SideMenuReturnVariantProps;
  group: LayoutSideMenuGroup;
  pathname: string;
}

function SideMenuActivePageMarker({
  styles,
  group,
  pathname,
}: SideMenuActivePageMarkerProps) {
  const itemHeight = remToPx(2);
  const offset = remToPx(0.25);

  const activePageIndex = group.menu.findIndex(
    (link) => link.link === pathname
  );
  const top = offset + activePageIndex * itemHeight;

  return (
    <m.div
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      className={styles.marker()}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      layout
      style={{ top }}
    />
  );
}

export default SideMenuActivePageMarker;
