import { m } from "framer-motion";
import { Share } from "../../util/share";
import { headerShareBarVariant } from "./header.variants";
import useHeaderState from "./use-header-state";

const variants = {
  hidden: { y: -76, opacity: 0 },
  visible: { y: 0 },
  visible_with_menu: { y: 80 },
};

function HeaderShareBar() {
  const { share, scrollYProgress } = useHeaderState();
  const styles = headerShareBarVariant();

  return (
    <m.aside
      animate={share}
      className={styles.base()}
      initial={{ y: -76 }}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
      variants={variants}
    >
      <div className={styles.content()}>
        <Share />
      </div>
      <div className={styles.progress()}>
        <m.div
          className={styles.currentProgress()}
          style={{ scaleX: scrollYProgress, transformOrigin: 0 }}
        />
      </div>
    </m.aside>
  );
}

export default HeaderShareBar;
